import React, { useState, useEffect } from "react"
import useFetch from "use-http"
import styled, { css } from "styled-components"

import ContentLayout from "../components/ContentLayout"
import Spinner from "../components/Spinner"
import Input from "../components/Input"
import Label from "../components/Label"

import { useUser } from "../hooks/useUser"

const Button = styled.button`
  height: 50px;
  margin-bottom: 10px;
  width: 100%;
  color: white;
  border-radius: 5px;
  box-shadow: #00000044 0px 0px 2px;
  font-size: 16px;
  background: ${({ color }) => color || "lightgray"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SettingsPage = () => {
  const { neptun, password, loggedIn, logout, setToken } = useUser()
  const [_neptun, setNeptun] = useState(neptun)
  const [_password, setPassword] = useState(password)
  const { post, response, loading, error } = useFetch(
    process.env.GATSBY_BACKEND_URL,
    {
      cachePolicy: "no-cache",
    }
  )

  const login = async () => {
    const user = await post("/login", { neptun: _neptun, password: _password })
    if (response.ok) {
      if (user.token) {
        setToken(user.token)
      }
    }
  }

  useEffect(() => {
    if (neptun && password && !_neptun && !_password) {
      setNeptun(neptun)
      setPassword(password)
    }
  }, [neptun, password, _neptun, _password])

  const handleLogout = () => {
    setNeptun("")
    setPassword("")
    logout()
  }

  return (
    <ContentLayout title="Beállítások">
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px;
        `}
      >
        <Label>Neptun kód</Label>
        <Input
          placeholder="Neptun kód"
          value={_neptun}
          disabled={loggedIn}
          onChange={e => {
            setNeptun(e.target.value)
          }}
        />
        <Label>Jelszó</Label>
        <Input
          type="password"
          placeholder="Neptun jelszó"
          disabled={loggedIn}
          value={_password}
          onChange={e => setPassword(e.target.value)}
        />
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
          `}
        >
          {loggedIn ? (
            <Button onClick={handleLogout} color="#cc0000">
              Kijelentkezés
            </Button>
          ) : (
            <Button onClick={login} color="#007e33">
              {loading ? (
                <Spinner size={30} line={3} color="white" />
              ) : (
                "Bejelentkezés"
              )}
            </Button>
          )}
        </div>

        {error && (
          <div
            css={css`
              color: red;
              font-size: 14px;
              text-align: center;
            `}
          >
            {response.data?.message || error.message}
          </div>
        )}
      </div>
    </ContentLayout>
  )
}

export default SettingsPage
